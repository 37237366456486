/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-diff-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0M9.5 3.5v-2l3 3h-2a1 1 0 01-1-1M8 6a.5.5 0 01.5.5V8H10a.5.5 0 010 1H8.5v1.5a.5.5 0 01-1 0V9H6a.5.5 0 010-1h1.5V6.5A.5.5 0 018 6m-2.5 6.5A.5.5 0 016 12h4a.5.5 0 010 1H6a.5.5 0 01-.5-.5"/>',
    },
});
